import React from "react";
import PropTypes from "prop-types";

import RouteListField from "Containers/RouteListField";
import ContentLayout from "Components/ContentLayout";

class EnvironmentSettingsRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { projectId, environmentId, organizationId } = this.props.params;

    return (
      <ContentLayout className="settings">
        <RouteListField
          projectId={projectId}
          environmentId={environmentId}
          organizationId={organizationId}
        />
      </ContentLayout>
    );
  }
}

EnvironmentSettingsRoutes.propTypes = {
  params: PropTypes.shape({
    projectId: PropTypes.string.isRequired,
    environmentId: PropTypes.string.isRequired,
    organizationId: PropTypes.string.isRequired
  })
};

export default EnvironmentSettingsRoutes;
