import styled from "styled-components";

export default styled.div`
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding: 0 0 56px;
  position: relative;
  &.settings-wrapper {
    padding-top: 15px;
  }
  &.setting-return {
    padding-top: 0;
    margin-top: -16px;
  }
`;
