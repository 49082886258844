import styled from "styled-components";

export default styled.div`
  font-size: 14px;
  line-height: 26px;
  color: #38485e;
  margin-bottom: 32px;
  max-width: 840px;
  .info-dialog {
    float: left;
    margin: 0 8px 16px -4px;
  }
  p {
    line-height: 24px;
    margin-bottom: 16px !important;
  }
  a {
    color: ${props => props.theme.links};
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;
