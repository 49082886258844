import React from "react";
import styled, { keyframes } from "styled-components";
import PropTypes from "prop-types";

import CopyButton from "Components/CopyButton";

const fadeInOut = keyframes`
  0% {
    background: rgba(71, 134, 255, 0);
  }
  30% {
    background: rgba(71, 134, 255, 1);
  }
  70% {
    background: rgba(71, 134, 255, 1);
  }
  100% {
    background: rgba(71, 134, 255, 0);
  }
`;

const Layout = styled.div`
  hr {
    background: #c9d0e4;
    border: none;
    height: 1px;
    margin-bottom: 16px;
  }
  pre {
    line-height: 21px;
    font-size: 12px;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    white-space: normal;
    margin-right: 0;
    margin-bottom: 20px;
    padding: 16px;
    border-radius: 2px;
    span {
      white-space: pre;
    }
  }
  &.text-button {
    .copy-to-clipboard {
      background: transparent;
      color: ${props => props.theme.links};
      svg > g > g {
        fill: ${props => props.theme.links};
      }
      &:hover {
        background: ${props => props.theme.buttonBg};
        color: #fff;
        svg > g > g {
          fill: #fff;
        }
      }
      &:active {
        backgrond: ${props => props.theme.buttonHover};
      }
    }
  }
  &.single-line,
  &.three-line {
    display: flex;
    pre {
      white-space: nowrap !important;
      align-items: center;
      height: 60px;
      line-height: 40px !important;
      margin: 0;
      padding: 0 16px 20px;
      min-width: 0;
      border-radius: 2px 0 0 2px;
    }
    button {
      font: 0/0 none;
      padding: 0;
      width: 40px;
      height: 40px;
      border-radius: 0 2px 2px 0;
      justify-content: center;
      background: #fff;
      border: 1px solid #c9d0e4;
      margin: -1px;
      svg {
        margin: 0;
        > g > g {
          fill: ${props => props.theme.links};
        }
      }
      &:hover {
        background: ${props => props.theme.buttonBg};
        border: none;
        margin: 0;
        svg {
          margin: 0;
          > g > g {
            fill: #fff;
          }
        }
      }
      &:focus {
        margin: 0 !important;
      }
    }
    .copied-message {
      animation: ${fadeInOut} 0.4s linear 1;
      border-radius: 0 2px 2px 0;
    }
    .copy-content-wrapper {
      height: 40px;
      border-radius: 2px 0 0 2px;
    }
  }
  &.three-line {
    .copy-content-wrapper {
      height: 77px;
      pre {
        height: 77px;
        line-height: 16px !important;
        padding-top: 12px;
      }
    }
    button,
    .copied-message {
      height: 77px;
    }
  }
`;
const ContentWrapper = styled.div`
  overflow: hidden;
  width: 100%;
`;

const Content = styled.pre`
  min-width: 200px;
  max-width: 300px;
  overflow-x: auto;
`;

const CopyableArea = ({
  children,
  content,
  id,
  textButton,
  singleLine,
  threeLine,
  linkStyle,
  actions
}) => (
  <Layout
    className={`copyable-area${textButton ? " text-button" : ""}${
      singleLine ? " single-line" : ""
    }${threeLine ? " three-line" : ""}`}
  >
    <ContentWrapper className="copy-content-wrapper">
      <Content id={id} tabIndex="0">
        {children}
      </Content>
    </ContentWrapper>
    <CopyButton
      id={`${id}-copy-to-clipboard-btn`}
      className={linkStyle ? "link-style" : ""}
      text={content ? content : ""}
      icon={false}
      tabIndex="0"
    />
    {actions}
  </Layout>
);

CopyableArea.propTypes = {
  content: PropTypes.string,
  id: PropTypes.string,
  children: PropTypes.node,
  textButton: PropTypes.bool,
  singleLine: PropTypes.bool,
  threeLine: PropTypes.bool,
  linkStyle: PropTypes.bool,
  actions: PropTypes.node
};

export default CopyableArea;
