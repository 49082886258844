import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Map } from "immutable";
import styled from "styled-components";
import { injectIntl } from "react-intl";
import { LiveMessage } from "react-aria-live";
import { Link } from "react-router";

import Heading2 from "Components/styleguide/Heading2";
import PageDescription from "Components/PageDescription";
require("codemirror/mode/yaml/yaml");
require("codemirror/mode/yaml-frontmatter/yaml-frontmatter");
import ListGroup from "Components/ListGroup";
import CopyableArea from "Components/CopyableArea";

const RouteList = styled.div`
  p {
    margin: 0 0 16px;
  }
  .description {
    margin-bottom: 32px;
  }
  .ReactCodeMirror {
    margin-bottom: 30px;
  }
  .route-form-wrapper {
    padding: 30px;
    min-height: 200px;
    width: 100%;
    box-sizing: border-box;
    border-bottom: 1px solid #e6e6e6;
    &.add-form {
      background: #fff;
      border-radius: 4px;
      box-shadow: 0 2px 6px rgba(152, 160, 171, 0.4);
      margin-bottom: 16px;
    }
  }
  .routes-example {
    padding: 32px;
    box-sizing: border-box;
    border-radius: 4px;
    pre {
      white-space: pre;
    }
  }
`;

class RouteListField extends React.Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.addNewRoute = this.addNewRoute.bind(this);
    this.save = this.save.bind(this);
    this.editLine = this.editLine.bind(this);
    this.cancel = this.cancel.bind(this);
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.isChanged = this.isChanged.bind(this);
    this.state = {
      routes: [],
      isModalLeaveOpen: false,
      isChanged: false,
      index: "",
      code:
        "# define the entry point.\n" +
        '"https://{default}/":\n' +
        "    # can be upstream or redirect.\n" +
        "    type: upstream\n" +
        "    # defines the upstream application defined in the .platform.app.yaml file.\n" +
        '    upstream: "php:http"\n' +
        "\n" +
        "# define the entry point.\n" +
        '"http://www.{default}/":\n' +
        "    # can be upstream or redirect.\n" +
        "    type: redirect\n" +
        "    # set the redirect target path.\n" +
        '    to: "https://{default}/"\n' +
        "\n" +
        "# define the entry point.\n" +
        '"http://{default}/":\n' +
        "    # can be upstream or redirect.\n" +
        "    type: redirect\n" +
        "    # set the redirect target path.\n" +
        '    to: "https://{default}/"\n' +
        "\n" +
        "# define the entry point.\n" +
        '"https://www.{default}/":\n' +
        "    # can be upstream or redirect.\n" +
        "    type: redirect\n" +
        "    # set the redirect target path.\n" +
        '    to: "https://{default}/"'
    };
  }

  componentDidMount() {
    this.props.loadRoutes(this.props.environment);
    this.setState(() => ({
      changeIndex: false,
      routes: [...this.props.routes.valueSeq().toJS()]
    }));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.environment !== this.props.environment) {
      this.props.loadRoutes(nextProps.environment);
    }
    if (nextProps.routes !== this.props.routes) {
      this.setState(() => ({
        routes: [...nextProps.routes.valueSeq().toJS()]
      }));
    }
  }

  onChange(value, field, index) {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.routes[index] = {
        ...(this.state.routes[index] || this.props.routes[index]),
        isChanged: true,
        [field]: value
      };

      return nextState;
    });
  }

  isChanged() {
    this.setState({
      isChanged: true
    });
  }

  save(route) {
    this.props.updateRoute(this.props.environment, route);
    this.setState({
      editedLine: undefined,
      isChanged: false
    });
  }

  editLine(index) {
    if (this.props.editedLine === index || this.props.isNew) {
      this.cancel();
    } else {
      if (this.state.isChanged && !this.state.isModalLeaveOpen) {
        this.setState({
          isModalLeaveOpen: true,
          isChanged: true,
          changeIndex: true,
          index: index
        });
      } else {
        this.setState({
          isChanged: false,
          changeIndex: false,
          index: index
        });
        this.props.editLine(index);
      }
    }
  }

  cancel() {
    if (this.props.isNew) {
      this.setState(prevState => {
        const nextState = prevState;
        nextState.isNew = false;
        nextState.routes.splice(0, 1);
        return nextState;
      });
    }
    if (this.state.isChanged && !this.state.isModalLeaveOpen) {
      this.setState({
        isModalLeaveOpen: true,
        isChanged: true
      });
    } else {
      this.setState({
        isChanged: false,
        index: ""
      });
      this.props.cancel();
    }
  }

  addNewRoute() {
    this.setState(prevState => {
      const nextState = prevState;

      nextState.routes.unshift({});
      this.props.editLine(0, true);
      return nextState;
    });
  }

  updateCode(newCode) {
    this.setState({
      code: newCode
    });
  }

  openModal(routeObject) {
    this.setState({
      isModalOpen: true,
      routeObject
    });
  }

  closeModal() {
    this.setState({
      isModalOpen: false,
      isModalLeaveOpen: false
    });
  }

  render() {
    const { intl, environment } = this.props;
    return (
      <RouteList>
        <LiveMessage
          message={`${environment && environment.title} route settings`}
          aria-live="polite"
        />
        <Heading2 id="settings-heading" style={{ marginBottom: "16px" }}>
          {intl.formatMessage({ id: "routes" })}
        </Heading2>
        <PageDescription>
          <div className="description">
            Routes describe how an incoming HTTP request is going to be handled
            by Platform.sh.<br />They are defined using the{" "}
            <code>.platform/routes.yaml</code> file in your Git repository.
          </div>
          <p>
            Visit our{" "}
            <Link
              to="https://docs.platform.sh/configuration/routes.html"
              rel="noopener noreferrer"
              target="_blank"
            >
              documentation
            </Link>{" "}
            on Routes to find examples and settings for HTTP Cache, Redirects,
            and Server Side Includes.
          </p>
        </PageDescription>
        <section aria-labelledby="settings-heading">
          <ListGroup className="routes-example">
            <p>
              You can start with this simple example that routes all incoming
              traffic to your application and redirects www to the naked domain.
            </p>
            <p>
              For this example to work ensure that in your{" "}
              <code>.platform.app.yaml</code> file the name of your application
              is <code>app</code> or adjust the upstream property before saving.
            </p>
            <p>
              You should save the file to <code>.platform/routes.yaml</code>{" "}
              commit and push.{" "}
            </p>
            <CopyableArea>
              {'"https://{default}/":\n' +
                "    # Here the default route goes to an HTTP endpoint defined in our application.\n" +
                "    # 'app' is the name of the application as defined in your .platform.app.yaml file.\n" +
                "    type: upstream\n" +
                '    upstream: "app:http"\n' +
                '"http://www.{default}/":\n' +
                "    # set a redirect route from www to the naked domain\n" +
                "    type: redirect\n" +
                '    to: "https://{default}/"'}
            </CopyableArea>
          </ListGroup>
        </section>
      </RouteList>
    );
  }
}

const mapDispatchToProps = (dispatch, props) => ({
  loadRoutes: environment =>
    import("Reducers/environment/settings/route").then(reducer =>
      dispatch(
        reducer.loadRoutes(
          props.organizationId,
          props.projectId,
          props.environmentId,
          environment
        )
      )
    ),
  updateRoute: (environment, route) =>
    import("Reducers/environment/settings/route").then(reducer =>
      dispatch(reducer.updateRoute(environment, route))
    ),
  editLine: (index, isNew) =>
    import("Reducers/environment/settings/route").then(reducer =>
      dispatch(reducer.editLine(index, isNew))
    ),
  delete: route =>
    import("Reducers/environment/settings/route").then(reducer =>
      dispatch(reducer.deleteRoute(route))
    ),
  cancel: () =>
    import("Reducers/environment/settings/route").then(reducer =>
      dispatch(reducer.cancelUpdateRoute())
    )
});

const mapStateToProps = (state, props) => {
  const route = state.route || new Map();
  const environment = state.environment || new Map();

  return {
    routes: route.getIn(
      ["data", props.organizationId, props.projectId, props.environmentId],
      new Map()
    ),
    editedLine: route.get("editedLine"),
    isNew: route.get("isNew"),
    errors: route.get("errors", {}),
    environment: environment.getIn([
      "data",
      props.organizationId,
      props.projectId,
      props.environmentId
    ]),
    isLoading: route.get("loading"),
    isUpdateLoading: route.get("updateLoading")
  };
};

RouteListField.propTypes = {
  loadRoutes: PropTypes.func,
  onChange: PropTypes.func,
  updateRoute: PropTypes.func,
  delete: PropTypes.func,
  editLine: PropTypes.func,
  cancel: PropTypes.func,
  environment: PropTypes.object,
  routes: PropTypes.object,
  errors: PropTypes.object,
  isNew: PropTypes.bool,
  isLoading: PropTypes.bool,
  isUpdateLoading: PropTypes.bool,
  editedLine: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  intl: PropTypes.object
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RouteListField));
